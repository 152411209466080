.my-challenges-grid-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;
  .my-challenges-grid-left {
    width: 100%;
    @media (min-width: 991px) {
      width: 50%;
    }
  }
  .my-challenges-grid-right {
    width: 100%;
    padding-top: 30px;
    @media (min-width: 991px) {
      width: 50%;
      padding-left: 30px;
      padding-top: 0;
    }
  }
  .my-challenges-grid-table {
    overflow: auto;
    font-size: 14px;
    color: #848484;
    .challenges-date:not(.disabled) {
      cursor: pointer;
    }
    .grid-table-inner {
      width: fit-content;
    }
    span {
      color: #292929;
      &.win-text {
        font-size: 12px;
        margin-left: auto;
        display: block;
        width: max-content;
        padding: 8px;
      }
    }
  }
  .grid-table-top {
    display: flex;
    span {
      display: block;
      padding: 0 0 8px;
      white-space: nowrap;
      color: #848484;
    }
    .grid-table-top-left {
      width: 60px;
      min-width: 60px;
    }
    .grid-table-top-right {
      display: flex;
      flex-grow: 1;
      span {
        padding: 0 8px 8px;
        width: 10%;
      }
    }
  }
}

.grid-table-content {
  display: flex;
  .grid-table-content-left {
    display: flex;
    flex-direction: column;
    width: 60px;
    min-width: 60px;
    span {
      display: flex;
      white-space: nowrap;
      color: #848484;
      height: 56px;
      align-items: center;
      padding: 8px 0;
      &.start {
        color: #292929;
      }
    }
  }
  .grid-table-content-right {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .calendar-item {
    width: 10%;
    padding: 8px;
  }
}
