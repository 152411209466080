.challenges-popup-wrap {
  position: relative;

  .challenges-popup-inner {
    position: relative;
    z-index: 30;
    border-radius: 15px;
    border: 1px solid #d1d1d150;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;
  }

  .challenges-popup-top {
    display: flex;
    align-items: center;
    color: #757575;
    font-size: 14px;
    padding: 0 30px 20px;
    border-bottom: 1px solid #eee;
    flex-wrap: wrap;
  }

  .challenges-popup-statue {
    margin-right: 30px;
    margin-left: 10px;
  }

  .challenges-date-breadcrumbs {
    margin: 0 25px 0 0;
  }

  .challenges-popup-effectiveness {
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      display: block;
      margin-right: 10px;
      line-height: 1;
    }
  }

  .challenges-popup-actions {
    margin-left: auto;
  }

  .challenges-popup-details {
    padding: 30px;
    flex-grow: 1;
    overflow: auto;
    max-height: 330px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dedede;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #bbb;
    }
  }

  .challenges-detail-bottom {
    padding: 20px 30px 0;
    border-top: 1px solid #d1d1d150;
    display: flex;
    align-items: center;
  }

  .challenges-detail-item {
    color: #292929;
    margin: 0 0 15px;
    font-size: 14px;
    line-height: 1.4;
    &:last-child {
      margin: 0;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 5px;
    }
    p,
    li {
      margin: 0 0 10px;
      &:last-child {
        margin: 0;
      }
    }

    ol,
    ul {
      margin: 0;
      padding: 0 0 0 30px;
    }
  }

  .challenges-date span.day-number {
    font-weight: 500;
  }
  .challenge-status-wrap {
    align-items: center;
  }
  .challenges-detail-screens {
    display: flex;
    align-items: center;
    padding: 7px 5px;
    background: #eee;
    border-radius: 6px;
    margin-right: 15px;
    span {
      display: block;
      width: 35px;
      height: 80px;
      margin: 0 2px;
      background: #c4c4c4;
      border-radius: 5px;
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
  .challenges-detail-screens + .btn_primary {
    margin-left: auto;
  }
}
