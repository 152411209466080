.my-challenges-wrap {
  padding: 30px 0;

  .my-challenges-top {
    display: flex;
    align-items: center;
  }
  .my-challenges-top-right {
    margin-left: auto;
    padding-left: 10px;
  }

  .my-challenges-top-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    p {
      width: 100%;
      color: #848484;
      font-size: 14px;
      margin: 3px 0;
    }

    .streak-days {
      margin-left: 10px;
      color: #292929;
      font-size: 14px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      svg {
        display: block;
        margin-right: 4px;
      }
    }
    h2 {
      font-size: 26px;
      font-weight: 600;
    }
  }

  .grid-list-btn {
    display: flex;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background: #d1d1d1;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }

    .btn-item {
      width: 35px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.35;
      cursor: pointer;
      svg {
        width: 16px;
        height: auto;
      }
      &:hover {
        opacity: 0.6;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}

.output-images {
  width: max-content;
  display: flex;
  justify-content: center;
  background: #eee;
  padding: 5px 3px;
  border-radius: 5px;
  margin: 0 auto;
  img {
    display: block;
    margin: 0 2px;
    object-fit: cover;
  }
}

.btn-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #f0f0f0;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    background: #e5e5e5;
  }
  &::before,
  &::after {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: block;
    background: #a0a0a0;
  }
  span {
    display: flex;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #a0a0a0;
    margin: 0 2px;
  }
}

.challenge-details-popup {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 20;
  top: 60px;
  &::before {
    content: "";
    position: fixed;
    left: 50%;
    width: calc(50% - 16px);
    height: 100%;
    top: 60px;
    background: #fff;
    z-index: 22;
    border-left: 1px solid #ececec;
  }
  .container {
    height: 100%;
  }

  .challenges-popup-back {
    position: fixed;
    left: 0;
    width: 10%;
    height: 100%;
    z-index: 40;
    cursor: pointer;
    @media (min-width: 991px) {
      width: 50%;
    }
    svg {
      display: block;
      position: absolute;
      right: -34px;
      top: 40px;
      background: #fff;
      width: 40px;
      height: 40px;
      object-fit: contain;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #eee;
      &:hover {
        border-color: #ddd;
      }
      @media (min-width: 991px) {
        right: -20px;
      }
    }
  }
  .challenges-popup-wrap {
    width: 90%;
    margin-left: 10%;
    padding: 85px 0 40px 40px;
    border-left: 1px solid #ececec;
    background: #fff;
    height: 100%;
    z-index: 30;
    @media (min-width: 991px) {
      width: 50%;
      margin-left: 50%;
    }
  }
}
