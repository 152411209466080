.footer {
  background: #000;
  color: #fff;
  padding: var(--dl-space-space-fourunits) 0;
  font-size: 14px;
  font-family: "ES Rebond Grotesque", sans-serif;
}

.footer-wrap {
  display: flex;
}

.footer-left {
  width: 30%;
}

.footer-right {
  width: 70%;
}

.footer-cols-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-col {
  width: 25%;
  padding: 0 15px;
}

.footer-col h5 {
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: bold;
}

.footer-col ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-col ul li {
  margin: 0 0 15px;
  line-height: 1.4;
}
.footer-col ul li a:hover {
  color: #eee;
}

.footer-logo {
  margin: 0 0 20px;
}

.footer-left p {
  margin: 0 0 15px;
  line-height: 1.5;
}

.footer-social {
  margin: 25px 0 0;
}

.footer-social a {
  display: inline-block;
  margin-right: 15px;
}

.footer-social a:last-child {
  margin-right: 0;
}
.footer-social a:hover {
  opacity: 0.8;
}
@media (max-width: 767px) {
  .footer-wrap {
    flex-direction: column;
  }

  .footer-left {
    width: 100%;
    text-align: center;
  }

  .footer-right {
    width: 100%;
    margin: 40px 0 0;
  }
  .footer {
    padding: var(--dl-space-space-threeunits) 0;
  }
}
@media (max-width: 540px) {
  .footer-col {
    width: 50%;
    margin: 20px 0 0;
  }
  .footer-right {
    margin: 20px 0 0;
  }
  .footer-col h5 {
    margin: 0 0 15px;
  }
}
