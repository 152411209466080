.landing-hero-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  background: #fff;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.landing-hero-banner {
  width: 100%;
  height: auto;
  display: block;
  min-height: 250px;
  object-fit: cover;
}
.landing-hero-group1805 {
  top: 20px;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  z-index: 2;
  text-align: center;
}
.landing-hero-text {
  color: rgb(4, 2, 31);
  height: auto;
  font-size: 132px;
  font-style: normal;
  font-family: "ES Rebond Grotesque";
  font-weight: bold;
  line-height: 1.1;
  font-stretch: normal;
  text-decoration: none;
}
.landing-hero-desc {
  color: rgba(4, 2, 31, 1);
  height: auto;
  display: block;
  font-size: 24px;
  font-family: "ES Rebond Grotesque";
  font-weight: 400;
  line-height: 1.4;
  text-decoration: none;
}
@media (max-width: 1600px) {
  .landing-hero-container {
    padding-top: var(--dl-space-space-twounits);
    background-color: var(--dl-color-gray-white);
  }
}
@media (max-width: 1200px) {
  .landing-hero-text {
    font-size: 110px;
  }
  .landing-hero-desc {
    font-size: 21px;
  }
}
@media (max-width: 991px) {
  .landing-hero-text {
    font-size: 80px;
  }
  .landing-hero-desc {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .landing-hero-text {
    font-size: 60px;
  }
  .landing-hero-desc {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .landing-hero-text {
    font-size: 40px;
  }
  .landing-hero-desc {
    font-size: 13px;
  }
}
