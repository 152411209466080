.challenge-status-wrap {
  display: flex;

  .challenge-status {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    i {
      display: block;
      font-style: normal;
      color: #757575;
      font-size: 12px;
      margin: 2px 0 0;
    }
  }

  .challenge-status-icon {
    width: 24px;
    height: 24px;
    overflow: hidden;
    svg,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
