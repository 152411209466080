.image-uploader {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  background: inherit;
  .upload-block {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fcfcfc;
    border: 1.6px dashed #ececec;
    border-radius: inherit;
    padding: 15px;
    &:hover {
      background: #f8f8f8;
    }

    .upload-text span {
      cursor: pointer;
      text-decoration: underline;
    }

    .upload-text span:hover {
      text-decoration: none;
    }

    .upload-text {
      color: #757575;
      margin: 10px 0 0;
    }
    &.drag-active {
      background: #eee;
    }
  }
  .preview-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border: 1px solid #eee;
    border-radius: inherit;
    z-index: 7;
    .remove-btn {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: 15px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      z-index: 2;
      &:hover {
        background: #000;
      }
    }
  }
}

.upload-progress {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: inherit;
  border: 1px solid #eee;
  .progress-bar {
    background: #f1f1f1;
    position: absolute;
    z-index: 6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
