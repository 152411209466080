.hero-features-container {
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  position: relative;
  margin-top: -50px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.hero-features-group1772 {
  margin: 0 auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  max-width: 100%;
  box-shadow: 0px 8px 24px rgba(180, 177, 168, 0.3);
  align-items: stretch;
  flex-shrink: 1;
  border-radius: 12px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.hero-features-container1 {
  width: 18%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}
.hero-features-group1770 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 1;
  border-radius: 11px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}
.hero-features-group1770:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.hero-features-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: "ES Rebond Grotesque", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-stretch: normal;
}
.hero-features-group11 {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  margin-left: 10px;
}
.hero-features-pasted-image {
  width: 22px;
  height: 16px;
}
@media (max-width: 1600px) {
  .hero-features-container {
    background-color: transparent;
  }
}
@media (max-width: 1200px) {
  .hero-features-container {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
