.user-survey-wrap {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    padding: 60px 0;

    flex-direction: row-reverse;
  }

  .user-survey-left {
    width: 100%;
    @media (min-width: 768px) {
      width: 60%;
    }
  }

  .user-survey-right {
    width: 100%;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      width: 35%;
      margin-bottom: 0;
    }
    img {
      display: block;
      width: 100%;
      border-radius: 10px;
    }
  }

  .user-survey-top {
    margin: 0 0 30px;
    h2 {
      font-size: 42px;
      font-weight: bold;
      margin: 0 0 10px;
      line-height: 1.2;
    }
    p {
      font-size: 18px;
    }
  }

  .survey-form-question {
    font-size: 18px;
    color: #000;
  }

  .survey-form-options {
    margin: 20px 0;
  }

  .survey-option-item {
    margin: 0 0 15px;
    position: relative;
    width: max-content;
    button {
      display: inline-block;
      padding: 10px 20px;
      background: rgba(217, 217, 217, 0.4);
      border-radius: 60px;
      width: 280px;
      max-width: 100%;
      min-height: 60px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: rgba(217, 217, 217, 0.8);
      }
      &.selected {
        background: rgba(217, 217, 217, 1);
        &::before {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          background: url(../../assets/option-tick.svg) no-repeat center #000;
          position: absolute;
          top: 18px;
          left: 18px;
          border-radius: 50%;
        }
      }
    }
    .other-role-ip {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      background: #fff;
      border: 1px solid #858585;
      border-radius: 60px;
      overflow: hidden;
      input {
        width: 100%;
        height: 100%;
        display: block;
        border: 0;
        padding: 15px 25px;
        outline: 0;
      }
      button {
        width: 60px;
        background: #000;
        color: #fff;
        padding: 0;
        min-width: 60px;
        &:disabled {
          background: #858585;
          cursor: no-drop;
        }
      }
    }
  }
}

.survey-confirm-data {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: rgba(217, 217, 217, 0.4);
    border-radius: 60px;
    width: 280px;
    max-width: 100%;
    min-height: 60px;
    position: relative;
    margin: 0 0 30px;
    span {
      display: block;
    }
    button {
      display: block;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
