.my-challenges-table {
  margin: 10px 0;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
  }
  th {
    font-size: 14px;
    font-weight: 400;
    color: #848484;
    border-bottom: 1px solid #d1d1d150;
    padding: 10px 15px;
    text-align: left;
    min-width: 65px;
    &:first-child,
    &:nth-child(2) {
      padding: 10px 0;
    }
  }

  td {
    font-size: 16px;
    font-weight: 400;
    color: #292929;
    border-bottom: 1px solid #d1d1d150;
    padding: 15px;
    text-align: left;
    min-width: 65px;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    &:nth-child(2) {
      padding: 15px 0;
    }
    &:first-child {
      padding: 15px 0;
      border-bottom: 0;
    }
  }

  .challenges-date {
    border-color: #c6c6c6;
  }
  tr {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: calc(100% + 16px);
      height: calc(100% + 2px);
      border-radius: 12px;
      background: transparent;
      position: absolute;
      top: -1px;
      left: -8px;
      pointer-events: none;
      transition: all 0.2s ease;
    }
  }
  tbody tr {
    cursor: pointer;

    &:hover {
      &::before {
        background: #f9f9f9;
        transition: all 0.2s ease;
      }
      .challenges-date {
        border-color: #000;
      }
    }
  }

  tr.missing-streaks {
    &::before {
      display: none;
    }
    td {
      padding: 0;
      border: 0;
      font-size: 12px;
      color: #757575;
      padding-left: 5%;
      position: relative;
      span {
        background: #fff;
        position: absolute;
        top: -10px;
        padding-right: 10px;
      }
    }
  }
}
