button.btn_primary {
  background: #000;
  padding: 20px 0;
  color: #fff;
  border-radius: 60px;
  width: 280px;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  svg {
    position: absolute;
    margin-left: 10px;
    transform: translateY(-50%);
    top: 50%;
    transition: all 0.3s ease;
  }
  &:not([disabled]):hover {
    background: #111;
    svg {
      transform: translateY(-50%) translateX(10px);
    }
  }
  &[disabled] {
    background: rgba(0, 0, 0, 0.5);
    cursor: no-drop;
  }
}
