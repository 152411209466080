.why-component {
  width: 1310px;
  height: auto;
  display: flex;
  padding: 0 15px;
  position: relative;
  max-width: 100%;
  margin-top: var(--dl-space-space-fourunits);
  align-items: flex-start;
  flex-shrink: 1;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row-reverse;
}

.why-component:nth-child(even) {
  flex-direction: row;
}

.why-component-image {
  width: 55%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
}
.why-component-image img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0px 9.49609px 56.9766px 4.74805px rgba(0, 0, 0, 0.08);
  border-radius: 16.1333px;
}

.why-component-content {
  width: 45%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  padding-right: var(--dl-space-space-threeunits);
  padding-left: 0;
  flex-direction: column;
}
.why-component:nth-child(even) .why-component-content {
  padding-left: var(--dl-space-space-threeunits);
  padding-right: 0;
}

.why-component-title {
  color: rgb(22, 1, 62);
  height: auto;
  font-size: 28px;
  font-weight: bold;
  line-height: normal;
  padding-top: var(--dl-space-space-unit);
  font-stretch: normal;
  padding-bottom: var(--dl-space-space-unit);
}
.why-component-list {
  margin: 0 0 var(--dl-space-space-unit);
  padding: 0;
  list-style: none;
}

.why-component-btn {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  justify-content: center;
}
.why-component-link {
  color: rgb(255, 255, 255);
  height: auto;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  background-color: rgba(0, 0, 0, 1);
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 7px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-halfunit);
  transition: all 0.3s ease;
}
.why-component-link:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 767px) {
  .why-component {
    flex-direction: column !important;
  }
  .why-component-image {
    width: 100%;
  }
  .why-component-content {
    width: 100%;
    padding: var(--dl-space-space-halfunit) 0 0 !important;
  }
}
