.challenges-submit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.9);
  z-index: 99999;
  padding: 30px;
  overflow: auto;

  .submit-popup-container {
    width: 640px;
    max-width: 100%;
    margin: 0 auto;
    button.btn_primary {
      margin: 30px auto 0;
      display: block;
    }
    .submit-pop-close {
      width: max-content;
      padding: 5px;
      margin: 10px auto;
      cursor: pointer;
      color: #000;
      opacity: 0.3;
      display: block;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .submit-popup-inner {
    background: #ffffff;
    box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 30px;
  }

  .user-avatars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    img {
      display: block;
      width: 30px;
      height: 30px;
      background: #eee;
      border-radius: 50%;
      position: relative;
      z-index: 2;
    }
    img + img {
      margin-left: -5px;
      z-index: 1;
    }
  }

  h4.submit-popup-heading {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 5px;
  }

  .submit-popup-meta {
    text-align: center;
    font-size: 10px;
    color: #a3a3a3;
    position: relative;
    margin: 0 0 5px;
    span {
      display: block;
      padding: 5px 10px;
      position: relative;
      z-index: 2;
      background: #fff;
      width: max-content;
      margin: 0 auto;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #eaeaea;
      top: 50%;
      left: 0;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  .submit-popup-chats {
    padding: 10px 0 0;
  }

  .submit-popup-chat-item {
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &:last-child {
      margin: 0;
    }

    .submit-chat-avatar {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background: #eee;
      border-radius: 50%;
    }

    .submit-chat-content {
      border: 1px solid #eee;
      border-radius: 10px;
      padding: 15px;
      width: calc(100% - 110px);
      margin: 0 15px;
      font-size: 14px;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
    }

    .submit-chat-images {
      display: flex;
      justify-content: space-between;
      margin: 10px 0 0;
      img {
        display: block;
        width: calc(33.33% - 7px);
        height: auto;
        border-radius: 8px;
        border: 1px solid #aba286;
      }
    }
  }
}
