ul.effectiveness-meter {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  li {
    width: 5px;
    height: 14px;
    background: #d9d9d9;
    margin-right: 5px;
    border-radius: 2px;
    &:last-child {
      margin: 0;
    }
  }
  &.effective-score-1 {
    li.filled {
      background: #f64545;
    }
  }
  &.effective-score-2 {
    li.filled {
      background: #ff9b3f;
    }
  }
  &.effective-score-3 {
    li.filled {
      background: #f8c238;
    }
  }
  &.effective-score-4 {
    li.filled {
      background: #3aaa45;
    }
  }
}
