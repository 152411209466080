.login-wrap {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  h1 {
    font-size: 26px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1;
    @media (min-width: 768px) {
      font-size: 36px;
    }
  }

  p {
    color: #292929;
    font-size: 15px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}
.social-login {
  padding: 20px 0;
  .login-btn {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    img {
      display: block;
      width: 18px;
      margin-right: 10px;
      height: auto;
    }
    &:hover {
      background: #eee;
    }
  }
}

.or-text {
  display: block;
  position: relative;
  color: #767676;
  padding: 30px 0;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #c7c7c7;
    opacity: 0.5;
  }
  span {
    background: #fff;
    display: block;
    margin: 0 auto;
    padding: 0 5px;
    width: max-content;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
  }
}

.login-form {
  position: relative;
  font-size: 14px;
  color: #292929;
  margin: 15px 0 0;
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .email-ip-wrap {
    position: relative;
    input {
      display: block;
      width: 100%;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 10px;
      height: 50px;
      outline: none;
      &:focus,
      &:active {
        border-color: #0069e3;
      }
      &.error {
        color: #f64545;
        border-color: #f64545;
      }
      &.pass-ip {
        padding-right: 60px;
        font: small-caption;
        &::placeholder {
          font-family: Geist, sans-serif;
        }
      }
    }
    &.pass-ip-wrap {
      margin: 30px 0 0;
    }
  }

  span.error {
    margin: 10px 0 0;
    display: block;
    color: red;
  }

  button.login-email-btn {
    padding: 15px 30px;
    border-radius: 10px;
    background: #0069e3;
    color: #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      background: #006ae3f6;
    }
    &[disabled] {
      background: #0069e350 !important;
      cursor: no-drop;
    }
    &.btn-loading {
      color: transparent;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/loading-icon.png) no-repeat center;
        z-index: 2;
        animation: spin 1s infinite linear;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  flex-wrap: wrap;
  .login-email-btn {
    width: 100%;
    max-width: 236px;

    @media (min-width: 768px) {
      width: 50%;
    }
  }
}
.login-form-bottom {
  font-weight: 300;
}

.password-strength {
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  img {
    cursor: pointer;
    display: block;
  }
  span {
    display: block;
    width: 5px;
    height: 14px;
    border-radius: 2px;
    background: #d9d9d9;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
  &.strength-1 {
    span:nth-child(1) {
      background: #f64545;
    }
  }
  &.strength-2 {
    span:nth-child(1),
    span:nth-child(2) {
      background: #ff9b3f;
    }
  }
  &.strength-3 {
    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
      background: #f8c238;
    }
  }
  &.strength-4 {
    span {
      background: #4ebf60;
    }
  }
}
.feedback {
  color: #8e8e8e;
  font-size: 12px;
  margin-top: 4px;
}

.name-field-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 0;
  .email-ip-wrap {
    width: 100%;
    margin: 15px 0;
    @media (min-width: 541px) {
      width: calc(50% - 15px);
    }
    label {
      display: block;
      margin: 0 0 10px;
    }
  }
}

.remember-user {
  label {
    display: block;
    position: relative;
    width: max-content;
    margin: 15px 0 0;
    input {
      opacity: 0;
      position: absolute;
    }
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      &::before {
        content: "";
        width: 22px;
        height: 22px;
        border: 1px solid #c7c7c7;
        border-radius: 5px;
        background: #fff;
        display: block;
        margin-right: 8px;
      }
    }
    input:checked + span::before {
      content: "";
      background: #0069e3;
    }
  }
}
