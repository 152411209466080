.why-points {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-shrink: 1;
  margin-bottom: var(--dl-space-space-unit);
  padding-left: 33px;
  font-size: 18px;
  line-height: 1.4;
}
.why-points::before {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 5px;
  left: 0;
  background: url(../../assets/list-icon.svg);
  content: "";
}
