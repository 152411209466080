img {
  max-width: 100%;
}
.landing-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  font-family: "ES Rebond Grotesque", sans-serif;
}
.landing-page-container1 {
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.how-it-works-home {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.how-it-works-how-it-works {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding: var(--dl-space-space-threeunits) 0;
  justify-content: center;
}

.how-it-works-title-sec {
  text-align: center;
}

.how-it-works-title-text {
  color: rgba(4, 2, 31, 1);
  height: auto;
  display: block;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
  margin: 0 0 10px;
}
.how-it-works-title-desc {
  color: rgba(41, 41, 41, 1);
  height: auto;
  display: block;
  font-size: 22px;
  font-style: Regular;
  font-weight: 400;
  line-height: 1.4;
  font-stretch: normal;
  text-decoration: none;
}
.how-it-works-group1778 {
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  flex-shrink: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-wrap: wrap;
}
.group1778-item {
  width: 25%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.group1778-arrow {
  width: 10%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
  padding-top: 6%;
}
.how-it-works-text04 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
  margin: 25px 0 10px;
}
.how-it-works-text06 {
  color: rgba(41, 41, 41, 1);
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  font-stretch: normal;
}
.how-it-works-group1776 {
  width: 152px;
  height: 49.49116897583008px;
  display: flex;
  align-items: center;
  flex-shrink: 1;
}
.how-it-works-vector14 {
  width: 152px;
  height: 49px;
}
.how-it-works-vector15 {
  width: 7px;
  height: 7px;
}
.how-it-works-group1774 {
  width: 341px;
  height: 376px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.how-it-works-image141 {
  width: 280px;
  height: 280px;
  border-radius: 30px;
}
.how-it-works-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
}
.how-it-works-text13 {
  color: rgba(41, 41, 41, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-weight: 400;
  line-height: 19px;
  font-stretch: normal;
  text-decoration: none;
}
.how-it-works-group1777 {
  width: 152.4999542236328px;
  height: 35.344154357910156px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
}
.how-it-works-vector151 {
  width: 7px;
  height: 7px;
}
.how-it-works-vector141 {
  width: 152px;
  height: 35px;
}
.how-it-works-group1775 {
  width: 281px;
  height: 376px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.how-it-works-image142 {
  width: 280px;
  height: 280px;
  border-radius: 30px;
}
.how-it-works-text18 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
}
.how-it-works-text20 {
  color: rgba(41, 41, 41, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-weight: 400;
  line-height: 19px;
  font-stretch: normal;
  text-decoration: none;
}
.how-it-works-why-section {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background: #fdfbf6;
}
.landing-page-container4 {
  left: 0;
  width: 1320px;
  position: relative;
  max-width: 100%;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.how-it-works-group1806 {
  width: 100%;
  height: auto;
  display: flex;
  text-align: center;
  align-items: center;
  flex-shrink: 1;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.how-it-works-text25 {
  color: rgba(4, 2, 31, 1);
  height: auto;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
}
.how-it-works-text27 {
  color: rgba(41, 41, 41, 1);
  height: auto;
  font-size: 22px;
  font-style: Regular;
  text-align: left;
  font-weight: 400;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}
.landing-page-container5 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1600px) {
}
@media (max-width: 991px) {
  .hero-feature-item-group1771 {
    padding: var(--dl-space-space-halfunit);
    width: 19%;
  }

  .hero-feature-item-text {
    font-size: 13px;
  }

  .hero-features-group1770 {
    height: auto;
    padding: var(--dl-space-space-unit) var(--dl-space-space-halfunit);
  }

  .hero-features-text {
    font-size: 14px;
  }

  .hero-features-group11 {
    margin-left: 5px;
  }

  .hero-features-pasted-image {
    width: 14px;
    height: auto;
  }

  .hero-feature-item-text br {
    display: none;
  }

  .hero-feature-item-image13 {
    width: 32px;
  }

  .hero-features-container1 {
    width: 19%;
  }

  .hero-features-group1772 {
    padding: var(--dl-space-space-unit);
  }
  .how-it-works-title-desc {
    font-size: 18px;
  }
}
@media (max-width: 860px) {
  .hero-feature-item-group1771 {
    width: 25%;
    max-width: 25%;
  }

  .hero-features-container1 {
    margin-top: var(--dl-space-space-halfunit);
    width: 100%;
  }

  .hero-features-group1772 {
    flex-wrap: wrap;
  }

  .hero-feature-item-group1769 {
    flex-direction: column;
  }

  .hero-feature-item-text {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .how-it-works-group1786 {
    width: 100%;
  }
  .how-it-works-group1799 {
    width: 100%;
    padding-top: var(--dl-space-space-unit);
    padding-right: 0;
  }

  .how-it-works-group17861 {
    width: 100%;
  }
  .how-it-works-group17991 {
    width: 100%;
    padding-top: var(--dl-space-space-unit);
    padding-left: 0;
  }

  .how-it-works-group17862 {
    width: 100%;
  }
  .how-it-works-group17992 {
    width: 100%;
    padding-top: var(--dl-space-space-unit);
    padding-right: 0;
  }
  .how-it-works-group17863 {
    width: 100%;
  }
  .how-it-works-group17993 {
    width: 100%;
    padding-top: var(--dl-space-space-unit);
    padding-left: 0;
  }
  .group1778-item {
    width: 500px;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }

  .how-it-works-group1778 {
    flex-direction: column;
    align-items: center;
  }
  .group1778-arrow {
    width: 100%;
    align-items: center;
    padding: 70px 0;
  }
  .group1778-arrow img {
    transform: rotate(90deg);
    width: 100px;
    height: auto;
  }
  .how-it-works-title-desc {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .hero-feature-item-group1771 {
    width: 50%;
    max-width: 50%;
  }
}
