.processing-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.processing-main-content {
  width: 100%;
  height: 788px;
  display: flex;
  position: relative;
  margin-top: auto;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: auto;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 1);
}
.processing-rectangle23 {
  width: 600px;
  height: 600px;
  margin-top: auto;
  border-radius: 40px;
}
.processing-text {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  margin-top: -100px;
  text-align: left;
  font-family: "Inter";
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  margin-bottom: auto;
  text-decoration: none;
}
