.user-survey-container {
  background: #f4f2ee;
  min-height: calc(100vh - 60px);
}

.user-suvey-content {
  padding: 30px 0;
  text-align: center;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  .container {
    position: relative;
  }
}

.survey-cancel {
  margin: 20px auto 0;
  cursor: pointer;
  color: #292929;
  width: max-content;
  font-family: "ES Rebond Grotesque", sans-serif;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    position: absolute;
    right: 16px;
    top: -20px;
    margin: 0;
  }
}

.survey-title {
  font-size: 24px;
  color: #050220;
  font-weight: 700;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 36px;
  }
}

.survey-desc {
  font-size: 14px;
  margin: 5px 0 0;
  font-family: "ES Rebond Grotesque", sans-serif;
  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.survey-options-wrap {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px 0 0;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    margin: 30px 0 0;
  }
}

.so-image {
  flex-grow: 1;

  img {
    display: block;
    margin: 0 auto;
  }
}

.so-title {
  font-size: 18px;
  font-weight: 600;
  color: #050220;
  margin: 10px 0;
}

.so-desc {
  color: #42403c;
  font-family: "ES Rebond Grotesque", sans-serif;
  p {
    font-family: inherit;
  }
}

.survey-option {
  background: #fff;
  border-radius: 12px;
  width: 100%;
  margin: 10px auto;
  max-width: 360px;
  padding: 30px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 24px rgba(180, 177, 168, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid #fff;
  cursor: pointer;
  @media (min-width: 768px) {
    width: calc(33.33% - 20px);
    margin: 10px;
    max-width: 260px;
  }
  &:hover {
    box-shadow: 0px 8px 10px rgba(137, 132, 113, 0.4);
  }
  &.active {
    border-color: #c9af60;
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 28px;
      height: 28px;
      background: url(../../assets/icon-tick.svg) no-repeat center #b4963e;
      background-size: 50%;
      border-radius: 50%;
    }
  }
}

.survey-continue {
  margin: 20px 0 0;
  @media (min-width: 768px) {
    margin: 30px 0 0;
  }
  button.btn_primary {
    width: 172px;
    border-radius: 9px;
    padding: 20px;
    font-family: "ES Rebond Grotesque", sans-serif;

    &[disabled] {
      background: #000;
      color: rgba(255, 255, 255, 0.5);
      svg {
        opacity: 0.5;
      }
    }
  }
}
