.main-wrap {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
}

.main-publishing {
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
  }

  .prompt-block {
    position: relative;
    background: #000;
    color: #fff;
    padding: 20px;
    border-radius: 13px;
    &::after {
      content: "";
      width: 22px;
      height: 22px;
      border-radius: 4px;
      position: absolute;
      left: 15%;
      bottom: -7px;
      transform: rotate(46.29deg);
      background: inherit;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }
  }

  .prompt-content {
    font-size: 14px;
    position: relative;
    padding-left: 40px;
    .prompt-icon {
      position: absolute;
      max-width: 31px;
      max-height: 31px;
      left: 0;
      top: 2px;
      img,
      svg {
        display: block;
        margin: 0;
      }
    }
  }

  .prompt-label {
    position: absolute;
    top: -10px;
    background: #e2c2e6;
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    line-height: 1;
    font-size: 11px;
    color: #000;
    left: 25px;
  }

  .screens-block {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
  }

  .screen-item {
    width: calc(50% - 10px);
    @media (min-width: 768px) {
      width: calc(50% - 20px);
    }
  }

  .screen-holder {
    border: 8px solid #000;
    border-radius: 30px;
    overflow: hidden;
  }
  .screen-texts {
    margin: 15px 0 0;
    text-align: center;
    color: #323232;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.main-content {
  width: 100%;
  margin-top: 25px;
  @media (min-width: 768px) {
    width: 50%;
    margin-top: 0;
  }
  h2 {
    font-size: 24px;
    margin: 0 0 25px;
    line-height: 1.2;
    font-weight: 800;

    @media (min-width: 768px) {
      font-size: 36px;
      br {
        display: none;
      }
    }
    @media (min-width: 991px) {
      font-size: 48px;
    }
    @media (min-width: 1200px) {
      br {
        display: block;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    width: 460px;
    max-width: 100%;
    li {
      margin: 0 0 15px;
      position: relative;
      padding-left: 20px;
      color: #292929;
      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: #9c9377;
        border-radius: 50%;
        left: 0;
        top: 5px;
        position: absolute;
      }
    }
  }

  .start-form {
    margin: 60px 0 0;
    display: flex;
    width: 460px;
    max-width: 100%;
    input {
      display: block;
      width: 100%;
      padding: 15px;
      border: 1px solid #ececec;
      border-radius: 5px 0 0 5px;
      color: #000;
      border-right-color: transparent;
    }
    input::placeholder {
      color: #949494;
    }

    button {
      display: block;
      padding: 15px;
      background: #000;
      color: #fff;
      border-radius: 0 5px 5px 0;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      min-width: max-content;
      @media (min-width: 540px) {
        padding: 15px 30px;
      }
      &:hover {
        background: #333;
      }
      &[disabled] {
        cursor: no-drop;
        background: #333;
      }
      .dots {
        display: none;
      }
      &.loading {
        .dots {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #000;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
        }
      }
    }
  }

  .continue-buttons {
    display: flex;
    align-items: center;
    margin: 30px 0 0;
    flex-wrap: wrap;
    span {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      @media (min-width: 540px) {
        margin-right: 20px;
        width: max-content;
      }
    }

    button {
      display: block;
      padding: 10px 20px;
      border: 1px solid #e3e3e3;
      border-radius: 20px;
      margin-right: 10px;
      @media (min-width: 540px) {
        margin-right: 20px;
      }
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background: #e3e3e3;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
