.challenges-date-breadcrumbs {
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span.day-number {
    font-weight: 800;
  }

  ul.challenged-breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    color: #686251;
    flex-wrap: wrap;
    a {
      color: #292929;
      &:hover {
        color: #000;
      }
    }

    li {
      margin-right: 15px;
      position: relative;
      padding-right: 10px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: -5px;
        top: 0;
        width: 5px;
        height: 100%;
        background: url(../../assets/breadcrumb-divider.svg) no-repeat center;
        background-size: contain;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        a {
          cursor: default;
          color: #686251;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
