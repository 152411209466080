.challenges-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0;
  flex-direction: column;
  @media (min-width: 991px) {
    flex-direction: row;
  }
}

.challenges-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  .challenges-left-details {
    flex-grow: 1;
  }
  @media (min-width: 991px) {
    width: 45%;
    padding-right: 3%;
  }
}

.challenges-right {
  width: 100%;
  background: #e5ecf3;
  border-radius: 30px;
  padding: 30px 45px 30px 30px;
  margin: 30px 0 0;
  @media (min-width: 991px) {
    width: 55%;
    margin: 0;
  }
}

.left-detail-item {
  margin: 0 0 20px;
  color: #292929;
  h3 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 600;
  }
  p,
  li {
    margin: 0 0 15px;
  }

  ol,
  ul {
    margin: 0;
    padding: 0 0 0 30px;
  }
}

.challenges-left-bottom {
  margin: 30px 0 0;
}

.challenges-right-top {
  display: flex;
  margin: 0 0 15px;
  flex-direction: column;
  @media (min-width: 541px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.design-logo-title {
  display: flex;
  align-items: center;
  img {
    display: block;
    border-radius: 10px;
    width: 47px;
    height: 47px;
    object-fit: cover;
  }
  .design-title {
    margin-left: 10px;
  }
}

.refer-more-screens {
  margin: 15px 0 0;
  button {
    background: #000;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    svg {
      display: block;
      margin-left: 5px;
    }
    &:hover {
      background: #444;
    }

    span {
      display: block;
    }
  }
  @media (min-width: 541px) {
    margin: 0 -15px 0 0;
  }
}

.challenges-right-screens {
  display: flex;
  flex-wrap: wrap;
}

.right-screen-design {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 541px) {
    width: 45%;
  }
}

.right-divider {
  width: 100%;
  padding: 30px 10px;
  display: flex;
  svg {
    display: block;
    width: 100%;
    height: auto;
    max-width: 40px;
    margin: 0 auto;
    transform: rotate(90deg);
  }
  @media (min-width: 541px) {
    width: 10%;
    svg {
      transform: none;
      max-width: 100%;
    }
  }
}

.right-screen-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 541px) {
    width: 45%;
  }
}

.right-screen-design img {
  display: block;
  border-radius: 20px;
  width: 100%;
  height: auto;
  flex-grow: 1;
}

.screen-text {
  text-align: center;
  margin: 10px 0 0;
  font-size: 14px;
  color: #757575;
}

.right-info-wrap {
  width: 100%;
  height: auto;
  flex-grow: 1;
  background: #fff;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: calc(100% - 20px);
    position: absolute;
    top: 10px;
    left: 15px;
    background: #f6f6f6;
    border-radius: inherit;
    pointer-events: none;
    z-index: 1;
  }
}
.right-info-wrap-container {
  background: #fff;
  z-index: 2;
  padding: 30px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.right-info-wrap h3 {
  margin: 0;
  font-size: 18px;
}

.right-detail-item {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 30px 0;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    max-width: 130px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 1px dotted #e4dabb;
  }
  &:last-child::after {
    display: none;
  }
}

.right-detail-item h4 {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 699;
  color: #292929;
}
