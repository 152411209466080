.challenges-date {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 10px;
  line-height: 1;
  margin-right: 15px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  color: #000000;
  &:last-child {
    margin-right: 0;
  }

  span.day-text {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1;
    position: relative;
    margin: 0 0 2px;
  }

  span.day-number {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-top: 1px;
  }
  &.current {
    &::before {
      content: "";
      width: 100%;
      height: 40% !important;
      top: 0 !important;
      left: 0;
      position: absolute;
      background: #f04645 !important;
    }
  }
  &.disabled {
    pointer-events: none;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #d9d9d9;
      position: absolute;
    }
  }
  &.completed {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #3aaa45;
      position: absolute;
    }
  }
  &.active {
    border-color: #c6c6c6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      top: 40%;
      left: 0;
      position: absolute;
      background: #d9d9d9;
    }
    &::after {
      display: none;
    }
  }
}
