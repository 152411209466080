.footer-minimal {
  color: #565656;
  font-size: 13px;
  text-align: center;
  padding: 20px 15px;
}
.footer-minimal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer-minimal a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
}

.copyright {
  margin: 3px 10px;
  display: flex;
}

.footer-minimal-links {
  margin: 3px 10px;
  display: flex;
  a {
    display: block;
    margin-left: 15px;
    &:first-child {
      margin: 0;
    }
  }
}
