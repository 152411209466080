.challenge-submit-wrap {
  padding: 30px 0;
}

.challenge-submit-top h2 {
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 18px;
}

.challenge-submit-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .submit-form-left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 768px) {
      width: 65%;
    }
  }

  .submit-form-right {
    width: 100%;
    margin-top: 30px;
    @media (min-width: 768px) {
      width: 35%;
      padding-left: 5%;
      margin-top: 0;
    }
  }
}

.submit-item {
  width: calc(33.33% - 10px);
  display: flex;
  flex-direction: column;
  @media (min-width: 991px) {
    width: calc(33.33% - 20px);
  }
  .image-upload {
    background: #eee;
    border-radius: 21px;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      border-radius: inherit;
      position: relative;
      z-index: 2;
      height: 100%;
      width: 100%;
      object-fit: contain;
      background: inherit;
    }
  }

  .item-text {
    text-align: center;
    margin: 10px 0 0;
    font-size: 14px;
    color: #757575;
  }
}

.form-right-data {
  display: flex;
  align-items: center;
  color: #292929;
  flex-wrap: wrap;
}

.form-right-item {
  margin: 0 0 25px;
  h3 {
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: 600;
  }

  label.form-challenge-mode {
    display: block;
    border: 1px solid #ececec;
    background: #f9f9f9;
    color: #292929;
    margin: 0 15px 8px 0;
    border-radius: 70px;
    position: relative;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #ededed;
    }
    &:last-child {
      margin-right: 0;
    }
    input {
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      opacity: 0;
      position: absolute;
      left: 0;
      cursor: pointer;
      outline: 0;
    }

    span {
      display: block;
      padding: 10px 20px;
      z-index: 2;
      position: relative;
      pointer-events: none;
    }
    input:checked + span {
      color: #fff;
      background: #000;
    }
  }
}

.form-duration {
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  input {
    border: 1px solid #ececec;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    width: 70px;
    display: block;
    color: #292929;
    margin: 0 10px 0 0;
    &::placeholder {
      opacity: 0.5;
    }
  }
}
.form-right-data {
  textarea {
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 15px;
    resize: none;
    height: auto;
    min-height: 200px;
    &::placeholder {
      opacity: 0.5;
    }
  }
}
.form-right-button {
  padding: 10px 0 0;
}
