.hero-feature-item-group1771 {
  width: 18%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: 20%;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 1;
  border-radius: 11px;
}
.hero-feature-item-group1771:hover {
  background-color: rgba(252, 233, 93, 1);
}
.hero-feature-item-group1769 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-shrink: 1;
}
.hero-feature-item-image13 {
  width: 50px;
  filter: grayscale(1);
  height: auto;
  display: block;
}
.hero-feature-item-group1771:hover .hero-feature-item-image13 {
  filter: grayscale(0);
}
.hero-feature-item-text {
  color: rgba(0, 0, 0, 1);
  display: block;
  padding: var(--dl-space-space-halfunit);
  flex-grow: 1;
  font-size: 16px;
  text-align: left;
  font-family: "ES Rebond Grotesque", sans-serif;
  font-weight: 400;
  line-height: 1.1;
  font-stretch: normal;
}
@media (max-width: 1200px) {
}
